import getDirectAdobeCampaignAxiosClient from '../../../common/api/clients/directAdobeCampaignAxiosClient';
import type { CustomErrorInterceptor } from '../../../common/api/clients/localApiAxiosErrorInterceptors';
import { createDefaultException } from '../../../common/exceptions/exceptionUtils';
import type { EmailSubscriptionResponse } from '../models';

const baseUrl = '/external/ac/offers';

type EmailSubscriptionApiException = {
    status: number;
    title: string;
};

const emailSubscriptionErrorInterceptor: CustomErrorInterceptor<EmailSubscriptionApiException> = (
    error,
) => {
    const exception = createDefaultException(
        error.response.data.title,
        error.response.data.status.toString(),
    );
    return exception;
};

const getSubscriptions = async (
    token?: string,
    neoId?: string,
): Promise<EmailSubscriptionResponse | null> => {
    const url = `${baseUrl}/subscriptions`;

    try {
        const response = await getDirectAdobeCampaignAxiosClient(token, neoId, [
            emailSubscriptionErrorInterceptor,
        ]).get(url);

        return response.data;
    } catch (error) {
        const err = error as { code?: string };
        if (err.code && err.code === '404') {
            return null;
        }

        throw error;
    }
};

const subscribe = async (services: string[], token?: string, neoId?: string, email?: string) => {
    const url = `${baseUrl}/subscriptions/services`;

    const axiosClient = await getDirectAdobeCampaignAxiosClient(token, neoId);
    const data = {
        email,
        services,
    };
    await axiosClient.post<void>(url, data);
};

const unsubscribe = async (services: string[], token?: string, neoId?: string) => {
    const url = `${baseUrl}/subscriptions/services`;

    const axiosClient = await getDirectAdobeCampaignAxiosClient(token, neoId);
    await axiosClient.delete<void>(url, { data: services });
};

const emailSubscriptionsApi = {
    getSubscriptions,
    subscribe,
    unsubscribe,
};

export default emailSubscriptionsApi;
