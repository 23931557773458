export const savedCartQueryKeys = {
    all: ['savedCarts'],
    byPaging: (page: number, pageSize: number) => [...savedCartQueryKeys.all, page, pageSize],
};

export const subscriptionsQueryKeys = {
    all: ['subscriptions'],
    byId: (id: string) => [...subscriptionsQueryKeys.all, id],
    timeslots: (subId: string) => [...subscriptionsQueryKeys.all, 'subscriptionTimeslots', subId],
    klarnaSnippet: ['subscriptionsKlarnaSnippet'],
};

export const orderReclamationsQueryKey = ['orderReclamations'];
export const deliveryAddressQueryKey = ['deliveryAddress'];
export const connectedCardsQueryKey = ['connectedCards'];

export const lastOrdersQueryKeys = {
    all: ['lastOrders'],
    byId: (id: string | undefined) => [...lastOrdersQueryKeys.all, id],
};

export const myCoopProfileMenuKeys = {
    all: ['myCoopProfileMenu'],
    settingsPage: ['myCoopSettingsPage'],
};

export const myCoopHouseholdKeys = {
    householdMembers: ['householdMembers'],
};

export const associationNamesQueryKey = ['associationNames'];

export const kimCustomerDataQueryKeys = {
    kimCustomerData: (invalidateCache: boolean) => ['kimCustomerData', invalidateCache],
};

// Poänguttag - Pointitem purchases
export const pointItemWithdrawalHistoryKeys = {
    all: ['pointItemWithdrawalHistory'],
    byDate: (from: Date, to: Date, page: number, size: number) => [
        ...pointItemWithdrawalHistoryKeys.all,
        from,
        to,
        page,
        size,
    ],
};

// Poänghistorik - point history
export const pointHistoryKeys = {
    all: ['pointHistory'],
    byDate: (from: Date, page: number, size: number) => [...pointHistoryKeys.all, from, page, size],
};

// Köphistorik - Money purchase history
export const purchaseHistoryKeys = {
    all: ['purchaseHistory'],
    byDate: (from: Date, page: number, size: number) => [
        ...purchaseHistoryKeys.all,
        from,
        page,
        size,
    ],
};

export const emailSubscriptionsKeys = {
    emailSubsriptionsByNeoId: (neoId: string) => ['emailSubsriptionsNeo', neoId],
    emailSubsriptionsByMmId: (mmId: string) => ['emailSubsriptions', mmId],
    emailSubsriptionsByEmail: (email: string) => ['emailSubsriptions', email],
};
