'use client';

import { CheckmarkIcon } from '@coop/icons';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import classNames from 'classnames';
import * as React from 'react';

import { Icon } from '../Icon';
import styles from './Checkbox.module.scss';

interface StyledCheckboxProps extends CheckboxPrimitive.CheckboxProps {
    label: React.ReactNode;
    labelVisuallyHidden?: boolean; // hide for render, but still announce when using screen reader
    disabled?: boolean;
    validationMsg?: string;
    theme?: 'shadowed' | 'default';
    indicatorVerticalPosition?: 'top' | 'center';
}

const StyledCheckbox = ({
    label,
    validationMsg,
    className,
    disabled = false,
    labelVisuallyHidden = false,
    theme = 'default',
    indicatorVerticalPosition: labeVerticalPosition = 'center',
    ...props
}: StyledCheckboxProps) => {
    const classes = classNames(
        styles.Checkbox,
        className,
        theme === 'shadowed' && styles['Checkbox-shadowed'],
        disabled && styles['is-disabled'],
        validationMsg && styles['is-invalid'],
    );

    return (
        <div className={classes}>
            <CheckboxPrimitive.Root
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                className={classNames(
                    styles.CheckboxButton,
                    labeVerticalPosition === 'center' && styles['CheckboxButton--center'],
                    labeVerticalPosition === 'top' && styles['CheckboxButton--top'],
                )}
                disabled={disabled}
            >
                {theme === 'shadowed' && <div className={classNames(styles.IndicatorShadow)} />}
                <CheckboxPrimitive.Indicator className={styles.Indicator}>
                    <Icon icon={CheckmarkIcon} />
                </CheckboxPrimitive.Indicator>
            </CheckboxPrimitive.Root>
            <label htmlFor={props.id} className={classNames(styles.Label)}>
                {labelVisuallyHidden ? <VisuallyHidden>{label}</VisuallyHidden> : label}
                {validationMsg && <span className={styles.ValidationMessage}>{validationMsg}</span>}
            </label>
        </div>
    );
};

export default StyledCheckbox;
