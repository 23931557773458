import { Browser } from '../../../utility/browser';
import type { TrackPurchaseRequest } from '../../search/models/models';
import trackingDirectApi from '../../tracking/api/trackingApi';
import type { ApiEventEntity, ApiEventsRequest } from '../../tracking/api/trackingRequests';
import { getDynamicYieldData } from './dynamicYield/dynamicYieldFlow';
import userFlow from './userFlow';

const productClick = async (clickEvent: ClickTrackingData, storeId: string) => {
    const dynamicYieldIds = clickEvent.slotId ? getDynamicYieldData() : {};
    const eventEntity: ApiEventEntity = {
        id: clickEvent.productId,
        type: 'Product',
    };
    if (clickEvent.slotId) {
        eventEntity.slotId = clickEvent.slotId;
    }

    const eventsRequest: ApiEventsRequest = {
        ...dynamicYieldIds,
        events: [
            {
                type: 'click',
                entity: eventEntity,
            },
        ],
    };

    const customerGroups = await userFlow.getUserCustomerGroups();
    const device = Browser.platformType();
    await trackingDirectApi.track(eventsRequest, storeId, customerGroups, device);
};

const recipeClick = async (clickEvent: RecipeClickTrackingData, storeId: string) => {
    const eventEntity: ApiEventEntity = {
        id: clickEvent.recipeId,
        type: 'RecipesEntity',
    };

    const eventsRequest: ApiEventsRequest = {
        events: [
            {
                type: 'click',
                entity: eventEntity,
            },
        ],
    };

    const customerGroups = await userFlow.getUserCustomerGroups();
    const device = Browser.platformType();
    await trackingDirectApi.track(eventsRequest, storeId, customerGroups, device);
};

const slotClick = async (clickEvent: ClickTrackingData, storeId: string) => {
    const dynamicYieldIds = getDynamicYieldData();
    const eventEntity: ApiEventEntity = {
        id: clickEvent.productId,
        slotId: clickEvent.slotId,
        type: 'Product',
    };

    const eventsRequest: ApiEventsRequest = {
        ...dynamicYieldIds,
        events: [
            {
                type: 'slotclick',
                entity: eventEntity,
            },
        ],
    };

    const customerGroups = await userFlow.getUserCustomerGroups();
    const device = Browser.platformType();
    await trackingDirectApi.track(eventsRequest, storeId, customerGroups, device);
};

const addToCart = async (productIds: string[], storeId: string) => {
    const eventsRequest: ApiEventsRequest = {
        events: productIds.map((productId) => ({
            type: 'addtocart',
            entity: {
                id: productId,
                type: 'Product',
            },
        })),
    };

    const customerGroups = await userFlow.getUserCustomerGroups();
    const device = Browser.platformType();
    await trackingDirectApi.track(eventsRequest, storeId, customerGroups, device);
};

const purchase = async (purchaseEvent: TrackPurchaseRequest, storeId: string) => {
    const eventsRequest: ApiEventsRequest = {
        events: purchaseEvent.products.map((event) => {
            return {
                type: 'purchase',
                entity: {
                    id: event.productId,
                    type: 'Product',
                },
                quantity: event.quantity,
                orderId: purchaseEvent.orderId,
                revenue: event.revenue,
            };
        }),
    };

    const customerGroups = await userFlow.getUserCustomerGroups();
    const device = Browser.platformType();
    await trackingDirectApi.track(eventsRequest, storeId, customerGroups, device);
};

const trackingFlow = {
    productClick,
    slotClick,
    addToCart,
    purchase,
    recipeClick,
};

export default trackingFlow;
