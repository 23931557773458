import { appConfig } from '../../../appConfig';
import emailSubscriptionsApi from '../../myCoop/emailSubscriptions/api/emailSubscriptionsApi';
import { getAuthToken } from '../api/auth/getAuthToken';
import { getNeoId } from '../utils/neoIdUtils';

const getSubscriptions = async (preferNeoId?: boolean) => {
    const neoId = (preferNeoId && getNeoId()) || undefined;
    const { isAuthenticated } = appConfig.coopUserSettings;
    let token;
    if (isAuthenticated) {
        const spaToken = await getAuthToken();
        token = spaToken?.token;
    }

    const response = await emailSubscriptionsApi.getSubscriptions(token, neoId);
    return response;
};

const subscribe = async (services: string[], preferNeoId?: boolean, email?: string) => {
    const neoId = (preferNeoId && getNeoId()) || undefined;
    const { isAuthenticated } = appConfig.coopUserSettings;
    let token;
    if (isAuthenticated) {
        const spaToken = await getAuthToken();
        token = spaToken?.token;
    }

    const response = emailSubscriptionsApi.subscribe(services, token, neoId, email);
    return response;
};

const unsubscribe = async (services: string[], preferNeoId?: boolean) => {
    const neoId = (preferNeoId && getNeoId()) || undefined;
    const { isAuthenticated } = appConfig.coopUserSettings;
    let token;
    if (isAuthenticated) {
        const spaToken = await getAuthToken();
        token = spaToken?.token;
    }

    const response = emailSubscriptionsApi.unsubscribe(services, token, neoId);
    return response;
};

export const emailSubscriptionFlow = {
    getSubscriptions,
    subscribe,
    unsubscribe,
};
